import { Route } from '@angular/router';
import {UserManagementResolvers} from "./user-management.resolvers";
import {UserManagementComponent} from "./user-management.component";

export const dailyReportRoutes: Route[] = [
    {
        path     : '',
        component: UserManagementComponent,
        resolve  : {
            data: UserManagementResolvers
        }
    }
];
