import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import {UserService} from "./services/user.service";


@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private locationSubscription: Subscription;
    currentUser;

    constructor(
        private _userService: UserService,
    ) {
    }

    ngOnInit() {
        if(this._userService.isLoggedIn()){
            this.currentUser = this._userService.getLoggedInUser();
            this.getUserLocation();
            // Setting up the interval to run every 5 minute (300000 milliseconds)
            this.locationSubscription = interval(300000).subscribe(() => {
                this.getUserLocation();
            });
        };
    }

    ngOnDestroy() {
        // Stop the interval when the component is destroyed
        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }
    }

    getUserLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log('Latitude:', position.coords.latitude, 'Longitude:', position.coords.longitude);
                    // Handle successful retrieval of position here
                    this._userService.updateUserGeo(this.currentUser._id,
                    {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude}
                    ).subscribe((res) => {
                        console.log(res);
                    });
                },
                (error) => {
                    if (error.code == error.PERMISSION_DENIED) {
                        console.error("User denied the request for Geolocation.");
                        alert('Please enable location services to use this app.');
                    }
                    // Handle other types of errors here
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
            // Handle browser not supporting Geolocation
        }
    }
}
