import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {of} from 'rxjs';
import {environment} from "../../environments/environment";

import {Router} from "@angular/router";
import {CommonUtilitiesService} from "./common-utilities.service";

export interface Department {
    _id: string,
    name: string,
    description: string,
}

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {

    public constructor(
        private _httpClient: HttpClient,
        private _commonUtilitiesService: CommonUtilitiesService,
        private _router: Router,
    ) {

    }

    getAvailableDepartments(){
        this.getDepartments({}).subscribe(result => {
            if (result && result.success){
                const availableDepartments = {};
                return result.departments.map(d => {
                    console.log(d);
                    availableDepartments[d.name] = d._id;
                })
            }
        })
    }

    getDepartment(id: any) {
        if (!id) {
            return of({
                success: false,
                error: 'department id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/department/${id}`
        );
    }

    getDepartments(params: object) {
        return this._httpClient.get<any>(
            `${environment.apiUrl}/department/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    createDepartment(params: object) {
        // params['apiKey'] = environment.kyipApiKey;
        return this._httpClient.post<any>(`${environment.apiUrl}/department/`, params);
    }

    updateDepartment(departmentId: string, params: object) {
        if (!departmentId) {
            return of({
                success: false,
                error: 'department id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/department/${departmentId}`, params);
    }

    deleteDepartment(departmentId: string) {
        if (!departmentId) {
            return of({
                success: false,
                error: 'department id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/department/${departmentId}`);
    }
}
