<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="bg-card">
        <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8">
            <div class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-8 sm:my-12">
                <!-- Avatar and name -->
                <div class="flex flex-auto items-center min-w-0">
                    <!--<div class="flex-0 w-16 h-16 rounded-full overflow-hidden">
                        <img
                            class="w-full h-full object-cover"
                            src="assets/images/avatars/brian-hughes.jpg">
                    </div>-->
                    <div class="flex flex-col min-w-0 ml-4">
                        <ng-container *transloco="let t">
                            <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">Users</div>
                        </ng-container>
                        <!--<div class="flex items-center">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:bell'"></mat-icon>
                            <div class="ml-1.5 leading-6 truncate text-secondary">You have 2 new messages and 15 new tasks</div>
                        </div>-->
                    </div>
                </div>
                <!-- Actions -->
                <div class="flex items-center mt-6 sm:mt-0 sm:ml-2 space-x-3">
                    <button *ngIf="currentUser && currentUser.roles && currentUser.roles.includes('admin')"
                            mat-flat-button
                            (click)="editUser()"
                            [color]="'primary'">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                        <span class="ml-2">Create User</span>
                    </button>
<!--                    <button-->
<!--                        class="bg-accent"-->
<!--                        mat-flat-button-->
<!--                        [color]="'accent'">-->
<!--                        <mat-icon-->
<!--                            class="icon-size-5"-->
<!--                            [svgIcon]="'heroicons_solid:mail'"></mat-icon>-->
<!--                        <span class="ml-2">Messages</span>-->
<!--                    </button>-->
<!--                    <button-->
<!--                        mat-flat-button-->
<!--                        [color]="'primary'">-->
<!--                        <mat-icon-->
<!--                            class="icon-size-5"-->
<!--                            [svgIcon]="'heroicons_solid:cog'"></mat-icon>-->
<!--                        <span class="ml-2">Settings</span>-->
<!--                    </button>-->
                </div>
            </div>
            <!-- Project selector -->
<!--            <div-->
<!--                class="relative flex self-start pt-2 pb-1 pl-5 pr-4 cursor-pointer overflow-hidden rounded-t-xl border border-b-0 bg-default"-->
<!--                matRipple-->
<!--                [matMenuTriggerFor]="daysMenu">-->
<!--                <div class="flex items-center">-->
<!--                    <div class="overflow-hidden">-->
<!--                        <div class="font-medium leading-6 truncate">{{selectedDay}}</div>-->
<!--                    </div>-->
<!--                    <div class="flex items-center justify-center pl-2">-->
<!--                        <mat-icon-->
<!--                            class="icon-size-5"-->
<!--                            [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <mat-menu-->
<!--                    #daysMenu="matMenu"-->
<!--                    [xPosition]="'before'">-->
<!--                    <button *ngFor="let month of goBackDays; let idx = index"-->
<!--                            mat-menu-item-->
<!--                            (click)="loadReportDay(idx)">{{getDayStartUI(idx)}}-->
<!--                    </button>-->
<!--                </mat-menu>-->
<!--            </div>-->
        </div>
    </div>

    <!-- Main -->
    <div class="flex-auto border-t -mt-px pt-4 sm:pt-6">
        <div class="w-full max-w-screen-xl mx-auto">
            <!-- Tabs -->
            <mat-tab-group
                class="sm:px-2"
                mat-stretch-tabs="false"
                (selectedTabChange)="roleTabSelect($event)"
                [animationDuration]="'0'">

                <mat-tab label="Active">
                    <ng-template matTabContent>
                        <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                            <div class="flex items-start justify-between">
                                <div class="text-lg font-medium tracking-tight leading-6 truncate">Users</div>
                                <div class="text-lg font-medium tracking-tight text-right truncate">Search:
                                    <input
                                        matInput
                                        style="background-color: #f6f6f6; border-radius: 4px; padding: 5px 19px;"
                                        [(ngModel)]="userFilterText">
                                </div>
                            </div>
                            <div class="mt-8">
                                <div class="-my-3 divide-y">
                                    <ng-container>
                                        <div class="grid grid-cols-8 py-3">
                                            <div class="flex col-span-2 font-medium text-left text-black ql-align-center"
                                                 (click)="alphabeticalAscending = !alphabeticalAscending"
                                            >Name
                                                <mat-icon *ngIf="alphabeticalAscending"
                                                          class="fuse-vertical-navigation-item-arrow icon-size-5"
                                                          [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
                                                <mat-icon *ngIf="!alphabeticalAscending"
                                                          class="fuse-vertical-navigation-item-arrow icon-size-5"
                                                          [svgIcon]="'heroicons_solid:chevron-up'"></mat-icon>
                                            </div>
                                            <div class="col-span-2 font-medium text-black text-left">Email</div>
                                            <div class="col-span-2 font-medium text-black text-left">Department</div>
                                            <div class="col-span-2 font-medium text-black text-left">Role</div>

                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let user of filteredUsers(); let i = index">
                                        <div class="grid grid-cols-8 py-3"
                                             *ngIf="user !== undefined"
                                             (click)="editUser(user)">
                                            <div class="col-span-2 font-medium text-gray-900 text-left">{{user.firstName}} {{user.lastName}}</div>
                                            <div class="col-span-2 font-medium text-gray-900 text-left">{{user.email}}</div>
                                            <div class="col-span-2 font-medium text-gray-900 text-left">{{departmentDict[user.departments[0]]}}</div>
                                            <div class="col-span-2 font-medium text-gray-900 text-left">{{roleDict[user.roles[0]]}}</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <!-- Home -->
                <mat-tab label="All">
                    <ng-template matTabContent>
                        <div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
                            <div class="flex items-start justify-between">
                                <div class="text-lg font-medium tracking-tight leading-6 truncate">Users</div>
                                <div class="text-lg font-medium tracking-tight text-right truncate">Search:
                                    <input
                                        matInput
                                        style="background-color: #f6f6f6; border-radius: 4px; padding: 5px 19px;"
                                        [(ngModel)]="userFilterText">
                                </div>
                            </div>
                            <div class="mt-8">
                                <div class="-my-3 divide-y">
                                    <ng-container>
                                        <div class="grid grid-cols-8 py-3">
                                            <div class="flex col-span-2 font-medium text-left text-black ql-align-center"
                                                 (click)="alphabeticalAscending = !alphabeticalAscending"
                                            >Name
                                                <mat-icon *ngIf="alphabeticalAscending"
                                                          class="fuse-vertical-navigation-item-arrow icon-size-5"
                                                          [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
                                                <mat-icon *ngIf="!alphabeticalAscending"
                                                          class="fuse-vertical-navigation-item-arrow icon-size-5"
                                                          [svgIcon]="'heroicons_solid:chevron-up'"></mat-icon>
                                            </div>
                                            <div class="col-span-2 font-medium text-black text-left">Email</div>
                                            <div class="col-span-2 font-medium text-black text-left">Department</div>
                                            <div class="col-span-2 font-medium text-black text-left">Role</div>

                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let user of filteredUsers(true); let i = index">
                                        <div class="grid grid-cols-8 py-3"
                                             *ngIf="user !== undefined"
                                             (click)="editUser(user)">
                                            <div class="col-span-2 font-medium text-gray-900 text-left">{{user.firstName}} {{user.lastName}}</div>
                                            <div class="col-span-2 font-medium text-gray-900 text-left">{{user.email}}</div>
                                            <div class="col-span-2 font-medium text-gray-900 text-left">{{departmentDict[user.departments[0]]}}</div>
                                            <div class="col-span-2 font-medium text-gray-900 text-left">{{roleDict[user.roles[0]]}}</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

</div>
