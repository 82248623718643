import { NgModule } from '@angular/core';
import {dailyReportRoutes} from "./user-management.routing";
import {UserManagementComponent} from "./user-management.component";
import {RouterModule} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatRippleModule} from "@angular/material/core";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {NgApexchartsModule} from "ng-apexcharts";
import {TranslocoModule} from "@ngneat/transloco";
import {SharedModule} from "../../../../shared/shared.module";
import {CreateUserComponent} from "../../../../layout/common/user/create-user/create-user.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {EditUserComponent} from "../../../../layout/common/user/edit-user/edit-user.component";



@NgModule({
  declarations: [
      UserManagementComponent,
      EditUserComponent,
  ],
  imports: [
      RouterModule.forChild(dailyReportRoutes),
      MatButtonModule,
      MatButtonToggleModule,
      MatDividerModule,
      MatIconModule,
      MatMenuModule,
      MatProgressBarModule,
      MatRippleModule,
      MatSidenavModule,
      MatSortModule,
      MatTableModule,
      MatTabsModule,
      NgApexchartsModule,
      TranslocoModule,
      SharedModule,
      MatDialogModule,
      MatFormFieldModule,
      MatInputModule,
      MatProgressSpinnerModule,
      MatCheckboxModule,
      MatSelectModule,
  ]
})
export class UserManagementModule { }
