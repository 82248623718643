import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    storageKey = 'token';

    storeToken(token: string): void {
        localStorage.setItem(this.storageKey, token);
    }

    async getToken(): Promise<string | null> {
        return localStorage.getItem(this.storageKey);
    }

    getTokenSync(): string | null {
        return localStorage.getItem(this.storageKey);
    }

    removeToken(): void {
        localStorage.removeItem(this.storageKey);
    }
}
