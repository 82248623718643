import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';
import {JwtInterceptor} from "./jwt.interceptor";
import {Interceptor} from "./http.interceptor";

@NgModule({
    imports  : [
        HttpClientModule
    ],
    providers: [
        AuthService,
    ]
})
export class AuthModule
{
}
