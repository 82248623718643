import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements  HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        if (token) {
            const re = 's3.us-east-1.amazonaws.com'; // if request is trying to upload to s3 do not intercept header
            const re2 = 's3.amazonaws.com'; // if request is trying to upload to s3 do not intercept header
            // Exclude interceptor for login request:
            if (request.url.search(re) === -1 && request.url.search(re2) === -1) {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + token
                    }
                });
                // console.log('header added!');
                // console.log(token);
            } else {
                // console.log('no header!');
            }

        }

        return next.handle(request);
    }
}
