import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormArray, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../../services/user.service";
import {AuthService} from "../../../../core/auth/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import {DepartmentService} from "../../../../services/department.service";

export interface DialogData {
    _id: string;
    nameFirst: string;
    nameLast: string;
    email: string;
}

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  animations   : fuseAnimations
})
export class EditUserComponent implements OnInit, OnDestroy{
    @ViewChild('signUpNgForm') editProfileNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
    };
    showAlert: boolean = false;

    editProfileForm: UntypedFormGroup;
    currentUser;
    formType;
    isAdmin: boolean = false;

    roleDict: any;
    roleOptions: any;

    departmentDict: any;
    departmentOptions: any;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService,
        private _userService: UserService,
        private _departmentService: DepartmentService,
        public dialogRef: MatDialogRef<EditUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {}

    onClose(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.isAdmin = this.data.isAdmin;
        this.formType = this.data.formType;
        this.roleDict = this._userService.availableRoles();
        this.roleOptions=Object.keys(this.roleDict);
        this.editProfileForm = this._formBuilder.group({
                firstName      : ['', Validators.required],
                lastName    :['', Validators.required],
                email     : ['', [Validators.required, Validators.email]],
                password: this.formType === 'new' ? ['', Validators.required] : [''],
                department: [''],
                disabled : [false],
                role: [''],
            }
        );
        this.getAvailableDepartments();
        if(this.formType === 'edit'){
            this.editProfileForm.controls.firstName.setValue(this.data.user.firstName);
            this.editProfileForm.controls.lastName.setValue(this.data.user.lastName);
            this.editProfileForm.controls.email.setValue(this.data.user.email);
            this.editProfileForm.controls.disabled.setValue(this.data.user.disabled);
            this.editProfileForm.controls.role.setValue(this.data.user.roles ? this.data.user.roles[0] : '');
            this.editProfileForm.controls.department.setValue(this.data.user.departments ? this.data.user.departments[0] : '');
        }
    }

    ngOnDestroy(): void {
        console.log('closed');
    }

    // get roles() {
    //     return this.editProfileForm.controls["roles"] as FormArray;
    // }
    //
    // // addRole(role) {
    // //     const roleForm = this._formBuilder.group({
    // //         role: [role, Validators.required],
    // //     });
    // //     this.roles.push(roleForm);
    // //     this.roleOptions.splice(this.roleOptions.indexOf(role), 1);
    // //     this.editProfileForm.controls.role.setValue('');
    // // }
    // //
    // // removeRole(roleIdx: number) {
    // //     this.roleOptions.push(this.editProfileForm.value.roles[roleIdx].role);
    // //     this.roles.removeAt(roleIdx);
    // // }

    getAvailableDepartments(){
        this._departmentService.getDepartments({}).subscribe(result => {
            if (result && result.success && result.departments){
                const availableDepartments = {};
                result.departments.map(d => {
                    if(!d.disabled){
                        availableDepartments[d._id] = d.name;
                    }
                });
                this.departmentDict = availableDepartments;
                this.departmentOptions=Object.keys(this.departmentDict);
            }
        })
    }

    updateUser(){
        try {
            if(this.formType === 'edit'){
                this._userService.updateUser(this.data.user._id, {
                    ...this.editProfileForm.value,
                    roles: [this.editProfileForm.value.role],
                    departments: [this.editProfileForm.value.department]
                }).subscribe(result => {
                    if(result.success){
                        this.onClose();
                    } else {
                        this.alert = {
                            type   : 'error',
                            message: `${result.message ? result.message : 'Something went wrong. Please try again'}`
                        };

                        // Show the alert
                        this.showAlert = true;
                    }
                })
            } else {
                this._userService.createUser({
                    ...this.editProfileForm.value,
                    roles: [this.editProfileForm.value.role],
                    departments: [this.editProfileForm.value.department]
                }).subscribe(result => {
                    if(result.success){
                        this.onClose();
                    } else {
                        this.alert = {
                            type   : 'error',
                            message: `${result.message ? result.message : 'Something went wrong. Please try again'}`
                        };

                        // Show the alert
                        this.showAlert = true;
                    }
                })
            }
        } catch (error){
            console.log(`err: ${error}`);
        }
    }

    deleteUser(){
        if (confirm("Delete this user?")) {
            this._userService.deleteUser(this.data.user._id).subscribe((result)=> {
                if(result.success){
                    this.onClose();
                } else {
                    this.alert = {
                        type   : 'error',
                        message: `${result.message ? result.message : 'Something went wrong. Please try again'}`
                    };

                    this.showAlert = true;
                }
            });
        }
    }
}
