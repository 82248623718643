<div class="sticky-header flex justify-between items-center mb-3">
    <div class="text-xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">
        {{formType && formType === 'edit' ? 'Edit User' : 'Create User'}}
    </div>
    <button class="close-button" (click)="onClose()">X</button>
</div>

<div class="scrollable-content" *ngIf="data">
    <form
        class="mt-8"
        [formGroup]="editProfileForm"
        #editProfileNgForm="ngForm">
        <!-- Name field -->
        <div style="display:flex; justify-content: space-between;">
            <mat-form-field class="" style="width: 48%">
                <mat-label>First Name</mat-label>
                <input
                    id="firstName"
                    matInput
                    [formControlName]="'firstName'">
                <mat-error *ngIf="editProfileForm.get('firstName').hasError('required')">
                    First name is required
                </mat-error>
            </mat-form-field>
           <mat-form-field class="" style="width: 48%">
                <mat-label>Last Name</mat-label>
                <input
                    id="lastName"
                    matInput
                    [formControlName]="'lastName'">
                <mat-error *ngIf="editProfileForm.get('lastName').hasError('required')">
                    Last name is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-row justify-between" style="align-items: center">
            <div style="width: 48%">
                <mat-form-field class="w-full">
                    <mat-label>Email address</mat-label>
                    <input
                        id="email"
                        matInput
                        [formControlName]="'email'">
                    <mat-error *ngIf="editProfileForm.get('email').hasError('required')">
                        Email address is required
                    </mat-error>
                    <mat-error *ngIf="editProfileForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
            </div>
            <div style="width: 48%">
                <mat-form-field class="w-full">
                    <mat-label>Password</mat-label>
                    <input
                        id="password"
                        matInput
                        type="password"
                        [formControlName]="'password'"
                        #passwordField>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                        matSuffix>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                    <mat-error *ngIf="formType === 'new'">
                        Password is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="flex flex-row justify-between" style="align-items: center">
            <div style="width: 48%">
                <mat-form-field class="w-full">
                    <mat-label>Department</mat-label>
                    <mat-select
                        [value]="this.editProfileForm.value.department"
                        [formControlName]="'department'"
                        #departmentSelector="matSelect"
                    >
                        <mat-select-trigger>
                    <span class="flex items-center">
                        <span>{{departmentSelector.triggerValue}}</span>
                    </span>
                        </mat-select-trigger>
                        <mat-option disabled [value]="''">Select Department to Assign</mat-option>
                        <mat-option *ngFor="let departmentOption of departmentOptions" [value]="departmentOption">{{departmentDict[departmentOption]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width: 48%">
                <mat-form-field class="w-full" *ngIf="roleOptions && roleOptions.length">
                    <mat-label>Role</mat-label>
                    <mat-select
                        [value]="this.editProfileForm.value.role"
                        [formControlName]="'role'"
                        #roleSelector="matSelect"
                    >
                        <mat-select-trigger>
                    <span class="flex items-center">
                        <span>{{roleSelector.triggerValue}}</span>
                    </span>
                        </mat-select-trigger>
                        <mat-option disabled [value]="''">Select Role to Add</mat-option>
                        <mat-option *ngFor="let roleOption of roleOptions" [value]="roleOption">{{roleDict[roleOption]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <mat-checkbox
            [formControlName]="'disabled'"
        >
            Disabled
        </mat-checkbox>

<!--        <fuse-alert-->
<!--            class="mt-8"-->
<!--            *ngIf="showAlert"-->
<!--            [appearance]="'outline'"-->
<!--            [showIcon]="false"-->
<!--            [type]="alert.type"-->
<!--            [@shake]="alert.type === 'error'">-->
<!--            {{alert.message}}-->
<!--        </fuse-alert>-->

        <!-- Submit button -->
        <button
            class="fuse-mat-button-large w-full mt-6"
            mat-flat-button
            [color]="'primary'"
            [disabled]="editProfileForm.disabled ||
            editProfileForm.get('email').hasError('required') ||
            editProfileForm.get('firstName').hasError('required') ||
            editProfileForm.get('lastName').hasError('required') ||
            (formType === 'new' && editProfileForm.get('password').hasError('required'))"
            (click)="updateUser()">
                    <span *ngIf="!editProfileForm.disabled">
                        {{formType === 'edit' ? 'Update' : 'Create'}}
                    </span>
            <mat-progress-spinner
                *ngIf="editProfileForm.disabled"
                [diameter]="24"
                [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
<!--        <button-->
<!--            class="fuse-mat-button-large w-full mt-0.5"-->
<!--            mat-flat-button-->
<!--            [color]="'accent'"-->
<!--            (click)="onClose()">-->
<!--                    <span>-->
<!--                        Close-->
<!--                    </span>-->
<!--        </button>-->
        <button
            *ngIf="formType !== 'new' && isAdmin"
            class="fuse-mat-button-large mr-5 w-full mt-0.5"
            mat-flat-button
            [color]="'warn'"
            [disabled]="!isAdmin"
            (click)="deleteUser()">
                                <span *ngIf="!editProfileForm.disabled">
                                    <mat-icon>delete_forever</mat-icon>
                                </span>
            <mat-progress-spinner
                *ngIf="editProfileForm.disabled"
                [diameter]="24"
                [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
    </form>
</div>
<!--<div mat-dialog-actions>
    <button mat-button (click)="onClose()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>-->
